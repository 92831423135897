/* eslint-disable @typescript-eslint/no-unused-vars */
import { ZodSchema, boolean, z } from "zod";
// import { pageMock } from "./page.mock";

export const imageSchema = z.object({
  name: z.string(),
  file_name: z.string(),
  original_url: z.string(),
  large_url: z.string(),
  medium_url: z.string(),
  mini_url: z.string(),
});

export const optionSchema = z.object({
  id: z.number(),
  name: z.string(),
  value: z.string(),
  values: z.array(z.string()).optional(),
});

export const skuSchema = z.object({
  id: z.number(),
  code: z.string(),
  price: z.number(),
  quantity: z.number(),
  images: z.array(imageSchema),
  options: z.array(optionSchema),
});

export type Sku = z.infer<typeof skuSchema>;

export const productSchema = z.object({
  id: z.number(),
  category_id: z.number(),
  slug: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  image: imageSchema,
  price_min: z.number().nullable(),
  price_max: z.number().nullable(),
  tags: z.array(z.object({ id: z.number(), name: z.string() })),
  skus: z.array(skuSchema),
  options: z.array(optionSchema),
});
export type Product = z.infer<typeof productSchema>;

export const partnerSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string().nullable().optional(),
  type: z.enum(["general", "main", "other"]).optional(),
  image: imageSchema,
});

export type Partner = z.infer<typeof partnerSchema>;

export const settingsSchema = z.object({
  maintenance_mode: z.boolean(),
  tickets_shop_url: z.string(),
  company_phone: z.string(),
  company_email: z.string(),
  company_name: z.string(),
  company_info: z.string(),
  countdown_datetime: z.string(),
  event_navigation_url: z.string(),
  event_latitude: z.string(),
  event_longitude: z.string(),
  company_instagram_handle: z.string(),
  company_instagram_url: z.string(),
  company_facebook_handle: z.string(),
  company_facebook_url: z.string(),
  company_youtube_handle: z.string(),
  company_youtube_url: z.string(),
  footer_links: z.array(
    z.object({
      link: z.string(),
      label: z.string(),
      target: z.enum(["_self", "_blank"]),
    }),
  ),
});

export type Settings = z.infer<typeof settingsSchema>;

export const deliveryOptionSchema = z.object({
  id: z.number(),
  name: z.string(),
  price: z.number(),
});

export type DeliveryOption = z.infer<typeof deliveryOptionSchema>;

export const paymentOptionSchema = z.object({
  id: z.number(),
  name: z.string(),
  price: z.number(),
});

export type PaymentOption = z.infer<typeof paymentOptionSchema>;

export const categorySchema = z.object({
  id: z.number(),
  slug: z.string(),
  name: z.string(),
});

export type Category = z.infer<typeof categorySchema>;

export const faqSchema = z.object({
  id: z.number(),
  question: z.string(),
  answer: z.string(),
});

export type Faq = z.infer<typeof faqSchema>;

export const articleSchema = z.object({
  id: z.number(),
  slug: z.string(),
  title: z.string(),
  content: z.string(),
  image: imageSchema,
});

export type Article = z.infer<typeof articleSchema>;

export const pageSchema = z.object({
  id: z.number(),
  slug: z.string(),
  title: z.string(),
  content: z.string(),
});

export type Page = z.infer<typeof pageSchema>;

export const createOrderSchema = z.object({
  payment_option_id: z.number().int(),
  delivery_option_id: z.number().int(),

  customer_contact_name: z.string().min(1).max(255),
  customer_email: z.string().min(1).max(255),
  customer_phone: z.string().min(1).max(255),
  customer_registration_id: z.string().max(255).optional(),
  customer_tax_id: z.string().max(255).optional(),
  customer_vat_id: z.string().max(255).optional(),
  customer_note: z.string().max(5000).optional(),

  customer_invoice_name: z.string().min(1).max(255),
  customer_invoice_country: z.string().min(1).max(255),
  customer_invoice_state: z.string().max(255).optional(),
  customer_invoice_city: z.string().min(1).max(255),
  customer_invoice_line1: z.string().min(1).max(255),
  customer_invoice_line2: z.string().min(1).max(255),
  customer_invoice_postal_code: z.string().min(1).max(255),

  customer_delivery_name: z.string().min(1).max(255),
  customer_delivery_country: z.string().min(1).max(255),
  customer_delivery_state: z.string().max(255).optional(),
  customer_delivery_city: z.string().min(1).max(255),
  customer_delivery_line1: z.string().min(1).max(255),
  customer_delivery_line2: z.string().min(1).max(255),
  customer_delivery_postal_code: z.string().min(1).max(255),

  discount_code: z.string().optional(),

  skus: z
    .array(
      z.object({
        id: z.number().int(),
        quantity: z.number().int().min(1),
      }),
    )
    .min(1),

  recaptcha_token: z.string(),
});

export type CreateOrder = z.infer<typeof createOrderSchema>;

export const createTshirtRequestSchema = z.object({
  name: z.string().min(1).max(255),
  email: z.string().min(1).max(255),
  phone: z.string().min(1).max(255),
  country: z.string().min(1).max(255),
  state: z.string().max(255).optional(),
  city: z.string().min(1).max(255),
  line1: z.string().min(1).max(255),
  line2: z.string().min(1).max(255),
  postal_code: z.string().min(1).max(255),
  tshirt_type: z.string().min(1).max(255),
  recaptcha_token: z.string().min(1).max(255),
});

export type CreateTShirtRequest = z.infer<typeof createTshirtRequestSchema> & {
  image?: File;
  ticket: File;
};

const getIsClient = () => {
  return typeof window !== "undefined";
};

const cacheDuration = 1 * 60 * 1000;

const fetchData = async <T>(
  url: string,
  schema: ZodSchema<T>,
  disableCache = false,
): Promise<T> => {
  const isClient = getIsClient();
  const doValidate = true;

  if (isClient && !disableCache) {
    try {
      const dataString = sessionStorage.getItem(url) ?? "";
      const json = JSON.parse(dataString);

      const data = await z
        .object({ data: schema, expires: z.number() })
        .parseAsync(json);

      if (data.expires > Date.now()) {
        return data.data as T;
      }
    } catch {
      // Who cares
    }
  }

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const json = await response.json();

  const data = doValidate ? await schema.parseAsync(json) : (json as T);

  // Save to cache
  if (isClient) {
    sessionStorage.setItem(
      url,
      JSON.stringify({ data, expires: Date.now() + cacheDuration }),
    );
  }

  return data;
};

export const createApiClient = (disableCache = false) => {
  const apiBaseUrl =
    typeof window !== "undefined"
      ? window.env.apiBaseUrl
      : process.env.API_BASE_URL;

  const getProducts = async (categoryId?: number) => {
    const { data } = await fetchData(
      `${apiBaseUrl}/products${categoryId ? `?category_id=${categoryId}` : ""}`,
      z.object({ data: z.array(productSchema) }),
      disableCache,
    );

    return data;
  };

  const getProductBySlug = async (
    slug: string,
    options?: { id: number; value: string | null }[],
  ) => {
    const params = (options ?? []).reduce((prev, option) => {
      if (option.value) {
        if (prev) {
          return `${prev}&options[${option.id}]=${option.value}`;
        } else {
          return `options[${option.id}]=${option.value}`;
        }
      }
      return prev;
    }, "");

    const { data } = await fetchData(
      `${apiBaseUrl}/products/${slug}?${params}`,
      z.object({ data: productSchema }),
      disableCache,
    );

    return data;
  };

  const getProductBySkuId = async (skuId: number) => {
    const { data } = await fetchData(
      `${apiBaseUrl}/skus/${skuId}`,
      z.object({ data: productSchema }),
      disableCache,
    );

    return data;
  };

  const getPartners = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/partners`,
      z.object({ data: z.array(partnerSchema) }),
      disableCache,
    );

    return data;
  };

  const getSettings = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/settings`,
      z.object({ data: settingsSchema }),
      disableCache,
    );

    return data;
  };

  const getDeliveryOptions = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/delivery-options`,
      z.object({ data: z.array(deliveryOptionSchema) }),
      disableCache,
    );

    return data;
  };

  const getPaymentOptions = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/payment-options`,
      z.object({ data: z.array(paymentOptionSchema) }),
      disableCache,
    );

    return data;
  };

  const getCategories = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/categories`,
      z.object({ data: z.array(categorySchema) }),
      disableCache,
    );

    return data;
  };

  const getFaqs = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/faqs`,
      z.object({ data: z.array(faqSchema) }),
      disableCache,
    );

    return data;
  };

  const getArticles = async () => {
    const { data } = await fetchData(
      `${apiBaseUrl}/articles`,
      z.object({ data: z.array(articleSchema) }),
      disableCache,
    );

    return data;
  };

  const getArticleBySlug = async (slug: string) => {
    try {
      const { data } = await fetchData(
        `${apiBaseUrl}/articles/${slug}`,
        z.object({ data: articleSchema }),
        disableCache,
      );

      return data;
    } catch {
      return null;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getPageBySlug = async (slug: string) => {
    try {
      const { data } = await fetchData(
        `${apiBaseUrl}/pages/${slug}`,
        z.object({ data: pageSchema }),
        disableCache,
      );
      return data;
    } catch {
      return null;
    }

    // return new Promise<Page>((resolve) => resolve(pageMock));
  };

  const createOrder = async (order: CreateOrder, returnUrl: string) => {
    const data = await fetch(
      `${apiBaseUrl}/orders/create?return_url=${returnUrl}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(order),
      },
    );

    const json = await data.json();

    const responseSchema = z
      .object({
        status: z.literal("OK"),
        payment_url: z.string(),
      })
      .or(
        z.object({
          errors: z.record(z.string(), z.array(z.string())).optional(),
        }),
      );

    const validatedData = await responseSchema.parseAsync(json);

    if ("payment_url" in validatedData) {
      return {
        isError: false,
        paymentUrl: validatedData.payment_url,
      } as const;
    }

    if ("errors" in validatedData) {
      return {
        isError: true,
        errors: validatedData.errors,
      } as const;
    }

    return {
      isError: true,
      errors: {},
    } as const;
  };

  const createTShirtRequest = async (input: CreateTShirtRequest) => {
    try {
      const formData = new FormData();

      formData.append("name", input.name);
      formData.append("email", input.email);
      formData.append("phone", input.phone);
      formData.append("country", input.country);
      formData.append("city", input.city);
      formData.append("line1", input.line1);
      formData.append("line2", input.line2);
      formData.append("postal_code", input.postal_code);
      formData.append("tshirt_type", input.tshirt_type);
      formData.append("recaptcha_token", input.recaptcha_token);

      if (input.image) formData.append("image", input.image);
      formData.append("ticket", input.ticket);

      const data = await fetch(`${apiBaseUrl}/tshirt-request/create`, {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });

      const json = await data.json();

      const responseSchema = z
        .object({
          status: z.literal("OK"),
        })
        .or(
          z.object({
            message: z.string(),
            errors: z.record(z.string(), z.array(z.string())).optional(),
          }),
        );

      const validatedData = await responseSchema.parseAsync(json);

      if ("message" in validatedData) {
        return {
          isError: true,
          errors: validatedData.errors,
        } as const;
      }

      return {
        isError: false,
      } as const;
    } catch {
      return {
        isError: true,
      } as const;
    }
  };

  type SubscribeToNewsletterResult =
    | {
        isError: true;
        message: string;
      }
    | {
        isError: false;
      };

  const subscribeToNewsletter = async (
    email: string,
  ): Promise<SubscribeToNewsletterResult> => {
    try {
      const data = await fetch(`${apiBaseUrl}/newsletter/create`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      if (data.ok) {
        return {
          isError: false,
        };
      }

      const json = await data.json();

      return {
        isError: true,
        message: json.errors.email[0] as string,
      };
    } catch {
      return {
        isError: true,
        message: "Vyskytla sa chyba",
      };
    }
  };

  const getDiscountPercentage = async (code: string) => {
    try {
      const discountSchema = z.object({
        discount: z.number(),
      });

      const { data } = await fetchData(
        `${apiBaseUrl}/coupons/${code}`,
        z.object({ data: discountSchema }),
        disableCache,
      );

      return data.discount;
    } catch {
      return 0;
    }
  };

  return {
    getSettings,
    getProductBySkuId,
    getProducts,
    getProductBySlug,
    getPartners,
    getCategories,
    getFaqs,
    getDeliveryOptions,
    getPaymentOptions,
    getPageBySlug,
    createOrder,
    getArticleBySlug,
    getArticles,
    subscribeToNewsletter,
    getDiscountPercentage,
    createTShirtRequest,
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
